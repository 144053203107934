import React, { FC } from 'react';

import { Rectangle } from './SmallSquareButton.style';
import { CloseIcon } from '../../assets/CloseIcon';
import { ArrowLeftIcon } from '../../assets/ArrowLeftIcon';
import { MenuIcon } from '../../assets/MenuIcon';
import { makeStyles } from '@mui/styles';
import { VideoAppTheme } from '../../../api/models/venue';
import { Theme } from '@mui/material';
import { theme } from '../../../theme';

export enum SmallSquareButtonType {
  Close,
  Hamburger,
  ArrowLeft,
}

interface SmallSquareButtonProps {
  onButtonClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  buttonType: SmallSquareButtonType;
  transparent?: boolean;
  color?: string;
  layoutTheme?: VideoAppTheme;
}

export const useStyles = makeStyles<Theme, { layoutTheme?: VideoAppTheme }>(() => ({
  close: ({ layoutTheme }) => ({
    '& path': {
      fill: layoutTheme === VideoAppTheme.Light ? 'var(--lightBlack)' : theme.palette.common.white,
    },
  }),
}));

export const SmallSquareButton: FC<SmallSquareButtonProps> = ({
  onButtonClick,
  buttonType,
  transparent,
  color,
  layoutTheme,
}) => {
  let icon;
  const classes = useStyles({ layoutTheme });
  switch (buttonType) {
    case SmallSquareButtonType.Close:
      icon = <CloseIcon classes={classes.close} />;
      break;
    case SmallSquareButtonType.Hamburger:
      icon = <MenuIcon color={color} />;
      break;
    case SmallSquareButtonType.ArrowLeft:
      icon = <ArrowLeftIcon />;
      break;
  }
  return (
    <Rectangle transparent={transparent} onClick={(e) => onButtonClick(e)}>
      {icon}
    </Rectangle>
  );
};
