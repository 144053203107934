import { FC } from 'react';

export const ArrowLeftIcon: FC = () => {
  return (
    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.02147 5.26026C0.791878 5.47786 0.662494 5.77603 0.662494 6.08717C0.662494 6.39853 0.791878 6.69647 1.02147 6.91431L5.89212 11.532C6.36373 11.9793 7.1209 11.9716 7.58319 11.5149C8.045 11.0581 8.03735 10.3252 7.56549 9.87795L4.78793 7.24466L15.1417 7.24466C15.802 7.24466 16.3375 6.72633 16.3375 6.08717C16.3375 5.448 15.802 4.92968 15.1417 4.92968L4.78817 4.92968L7.56549 2.29685C8.03735 1.84959 8.045 1.11713 7.58319 0.659925C7.1209 0.203411 6.36397 0.195772 5.89212 0.643026L1.02147 5.26026Z"
        fill="white"
      />
    </svg>
  );
};
