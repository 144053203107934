import { useEffect, useState } from 'react';

export const useModal = (defaultValue = false) => {
  const [show, setShow] = useState(defaultValue);

  useEffect(() => {
    setShow(defaultValue);
  }, [defaultValue]);

  const open = () => {
    setShow(true);
  };

  const close = () => {
    setShow(false);
  };

  const toggle = () => {
    setShow((v) => !v);
  };

  const reset = () => {
    setShow(defaultValue);
  };

  return {
    show,
    open,
    close,
    toggle,
    reset,
  };
};
