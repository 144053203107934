import { Typography, TypographyProps } from '@mui/material';

interface TextWithIconsProps extends TypographyProps {
  text: string;
  icons?: React.ReactNode | React.ReactNode[];
}

/**
 * Renders line of text with icon.
 * Define a place for your icon using "<icon>" tag
 *
 * @example
 * <TextWithIcon text="Some text <icon> with icon <icon>" icons={[<SomeIconComponent key="key1" />, <AnotherIconComponent key="key2" />]} />
 */
export const TextWithIcons: React.FC<TextWithIconsProps> = ({ text, icons, ...typographyProps }) => {
  const textParts = text.split('<icon>');

  const iconsArray: React.ReactNode[] = Array.isArray(icons) ? icons : [icons];

  return (
    <Typography sx={{ display: 'flex', alignItems: 'center' }} {...typographyProps}>
      {textParts.length && iconsArray?.length ? (
        <>
          {textParts.map((textPart, index) => {
            if (index === 0) return <>{textPart}</>;

            const icon = iconsArray[index - 1];
            return (
              <>
                &nbsp;
                {icon}
                &nbsp;
                {textPart}
              </>
            );
          })}
        </>
      ) : (
        text
      )}
    </Typography>
  );
};
