import { FC } from 'react';

export const RedditIcon: FC = () => (
  <svg width={29} height={24} viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.5 12.1928C28.5 10.5542 27.1201 9.25301 25.4391 9.25301C24.6111 9.25301 23.8584 9.56627 23.3065 10.0723C21.1989 8.62651 18.3136 7.68675 15.1022 7.56627L16.5072 1.25301L21.0735 2.19277C21.1237 3.30121 22.0771 4.19277 23.2563 4.19277C24.4606 4.19277 25.4391 3.25301 25.4391 2.09639C25.4391 0.93976 24.4606 0 23.2563 0C22.4032 0 21.6505 0.481928 21.2993 1.18072L16.2061 0.14458C16.0556 0.120483 15.905 0.144579 15.8047 0.216868C15.6792 0.289157 15.6039 0.409639 15.5789 0.554218L14.0233 7.59036C10.7616 7.68675 7.82617 8.62651 5.69355 10.0964C5.14158 9.59036 4.38889 9.27711 3.56093 9.27711C1.85484 9.27711 0.5 10.6024 0.5 12.2169C0.5 13.4217 1.25269 14.4337 2.30645 14.8916C2.25627 15.1807 2.23118 15.4699 2.23118 15.7831C2.23118 20.3133 7.72581 24 14.5 24C21.2742 24 26.7688 20.3373 26.7688 15.7831C26.7688 15.494 26.7437 15.1807 26.6936 14.8916C27.7473 14.4337 28.5 13.3976 28.5 12.1928ZM7.47491 14.2892C7.47491 13.1325 8.45341 12.1928 9.65771 12.1928C10.862 12.1928 11.8405 13.1325 11.8405 14.2892C11.8405 15.4458 10.862 16.3855 9.65771 16.3855C8.45341 16.4096 7.47491 15.4458 7.47491 14.2892ZM19.6936 19.8554C18.1882 21.3012 15.328 21.3976 14.5 21.3976C13.647 21.3976 10.7867 21.2771 9.30645 19.8554C9.08065 19.6386 9.08065 19.3012 9.30645 19.0843C9.53226 18.8675 9.88351 18.8675 10.1093 19.0843C11.0627 20 13.0699 20.3133 14.5 20.3133C15.9301 20.3133 17.9624 20 18.8907 19.0843C19.1165 18.8675 19.4677 18.8675 19.6936 19.0843C19.8943 19.3012 19.8943 19.6386 19.6936 19.8554ZM19.2921 16.4096C18.0878 16.4096 17.1093 15.4699 17.1093 14.3133C17.1093 13.1566 18.0878 12.2169 19.2921 12.2169C20.4964 12.2169 21.4749 13.1566 21.4749 14.3133C21.4749 15.4458 20.4964 16.4096 19.2921 16.4096Z"
      fill="white"
    />
  </svg>
);
