import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';

import { Modal, ModalProps } from '../../../common/components/Modal/Modal';
import BlockedVideoIcon from '../../../common/assets/BlockedVideoIcon.svg';
import SettingIcon from '../../../common/assets/SettingIcon.svg';
import { StoriesApiModel } from '../../../api/models/stories';
import { StoryStatuses } from '../../../store/slices/stories';

interface ModerationModalProps extends ModalProps {
  show: boolean;
  story: StoriesApiModel;
  onClose: () => void;
}

export const ModerationModal: React.FC<ModerationModalProps> = ({ show, story, onClose }) => {
  const theme = useTheme();

  if (!show) return <></>;

  const isPendingModeration = story.status === StoryStatuses.PENDING_MODERATION;
  const isModerated = story.status === StoryStatuses.MODERATED;

  if (!isPendingModeration && !isModerated) return <></>;

  const Icon = isPendingModeration ? SettingIcon : BlockedVideoIcon;
  const title = isPendingModeration ? 'Video Processing' : 'Video Moderated';
  const description = isPendingModeration
    ? 'While your video is processing, use the other share options to earn points and check back in a few more moments.'
    : 'You can record/upload a new video or contact us to learn why you video was moderated.';

  return (
    <Modal onClose={onClose}>
      <Box
        padding="16px"
        bgcolor="white"
        borderRadius="16px"
        display="flex"
        flexDirection="column"
        gap="16px"
        width="330px"
        alignItems="center"
      >
        <Icon />
        <Typography color={theme.palette.common.black} fontSize="22px" fontWeight="600">
          {title}
        </Typography>
        <Typography maxWidth="255px" textAlign="center" fontSize="14px" color={theme.palette.text.secondary}>
          {description}
        </Typography>
        <Button variant="contained" color="primary" fullWidth onClick={onClose}>
          <Typography color="white" fontSize="14px" fontWeight="600">
            Close
          </Typography>
        </Button>
      </Box>
    </Modal>
  );
};
