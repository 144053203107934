import { FC } from 'react';

export const DownloadIcon: FC = () => (
  <svg width={29} height={28} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 16.0266V4.66797H15.5V16.0266L19.09 12.6853L20.5 14.0013L14.5 19.6013L8.5 14.0013L9.91 12.6853L13.5 16.0266Z"
      fill="currentColor"
    />
    <path d="M21.5 23.3346V21.468H7.5V23.3346H21.5Z" fill="currentColor" />
  </svg>
);
