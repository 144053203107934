import { Box, BoxProps, Button, Collapse, Typography } from '@mui/material';
import React from 'react';
import { isChrome, isFirefox, isIOS, isSafari } from 'react-device-detect';

import { IosDownloadIcon, ShareIconIos } from '../../../../common/assets/shareIcons';
import { useModal } from '../../../../services/hooks/useModal';
import { TextWithIcons } from '../../../../common/components/TextWithIcons/TextWithIcons';
import { useThemeLayout } from '../../../../common/hooks/useThemeLayout';

const HelperText: React.FC<{
  text: string;
  icon?: React.ReactNode;
  color?: string;
}> = ({ text, icon, color = 'black' }) => {
  return <TextWithIcons text={text} icons={icon} fontSize="13px" lineHeight="28px" color={color} />;
};

export const SafariText: React.FC = () => {
  return (
    <>
      <HelperText text="1. Tap ‘Download’ when prompted" />
      <HelperText text="2. In address bar, tap on the <icon> icon" icon={<IosDownloadIcon color={'#000'} />} />
      <HelperText text="3. Tap ‘Downloads’ in the menu below icon" />
      <HelperText text="4. Select your video from the ‘Downloads’ list" />
      <HelperText text="5. Tap the <icon> icon to bring up share options" icon={<ShareIconIos color={'#000'} />} />
      <HelperText text="6. Tap “Save Video” to save to your phone" />
    </>
  );
};

const ChromeText: React.FC = () => {
  return (
    <>
      <HelperText text="1. Tap on the Download Your Video button" />
      <HelperText text='2. Tap on the "Open in..." on the download tab' />
      <HelperText text='3. Tap on "Open in Downloads" option' />
      <HelperText text="4. Tap on your video to view it" />
      <HelperText text="5. Tap the <icon> icon to bring up share options" icon={<ShareIconIos color={'#000'} />} />
      <HelperText text='6. Tap "Save Video" to save video to your phone' />
    </>
  );
};

export const DownloadVideoTextHelper: React.FC<BoxProps> = (props) => {
  const { show, toggle } = useModal(true);

  const { isThemeLayoutEnabled, themeTextColor } = useThemeLayout();

  // only iOS (Chrome/Safari) is supported
  if (!isIOS) return <></>;
  // hide for Firefox and iOS as for https://socialvenu.atlassian.net/browse/SV-155
  if (isIOS && isFirefox) return <></>;

  return (
    <Box {...props}>
      <Button fullWidth onClick={toggle}>
        <Typography
          fontSize="13px"
          textAlign="center"
          color={isThemeLayoutEnabled ? themeTextColor : 'white'}
          sx={{
            textDecorationLine: 'underline',
            fontWeight: '700',
            fontSize: '13px',
          }}
        >
          How to download your video
        </Typography>
      </Button>
      <Collapse in={show} timeout="auto" unmountOnExit>
        {isSafari && <SafariText />}
        {isChrome && <ChromeText />}
      </Collapse>
    </Box>
  );
};
