import { FC, SVGProps } from 'react';

export const IosDownloadIcon: FC<SVGProps<SVGSVGElement>> = ({ color = 'white', ...props }) => (
  <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <line
      x1={3.75}
      y1={20.25}
      x2={18.25}
      y2={20.25}
      stroke={color}
      strokeOpacity={0.3}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <line x1={3.75} y1={20.25} x2={7.25} y2={20.25} stroke={color} strokeWidth={1.5} strokeLinecap="round" />
    <path
      d="M11 16.5C15.1421 16.5 18.5 13.1421 18.5 9C18.5 4.85786 15.1421 1.5 11 1.5C6.85786 1.5 3.5 4.85786 3.5 9C3.5 13.1421 6.85786 16.5 11 16.5Z"
      stroke={color}
      strokeWidth={1.1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11 6.375V10.875" stroke={color} strokeWidth={1.1} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8.75 9.375L11 11.625L13.25 9.375"
      stroke={color}
      strokeWidth={1.1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
