import { useEffect, useRef } from 'react';
import { StoriesApiModel } from '../../api/models/stories';
import { StoryStatuses, getStoryById, shareStoryRefetching } from '../../store/slices/stories';
import { useAppDispatch, useTypedSelector } from '../../store/store';

const createIntervals = (requestsQty: number, interval: number) => {
  return [...Array(requestsQty).keys()].map(() => interval);
};

const defaultRefetchIntervals: number[] = [
  ...createIntervals(5, 2000),
  ...createIntervals(3, 10000),
  ...createIntervals(2, 15000),
];

export const useRefetchStoryUntilModerated = () => {
  const dispatch = useAppDispatch();

  const isRefetching = useTypedSelector((state) => state.stories.shareStoryRefetching);
  const setIsRefetching = (value: boolean) => {
    dispatch(shareStoryRefetching(value));
  };

  const timeoutRef = useRef<number>();

  // clear timeout unmount
  useEffect(() => {
    return () => {
      setIsRefetching(false);
      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStoryWithDelay = async ({
    storyId,
    delay,
  }: {
    storyId: StoriesApiModel['id'];
    delay: number;
  }): Promise<StoriesApiModel> => {
    return new Promise((resolve) => {
      timeoutRef.current = setTimeout(async () => {
        const res = await dispatch(getStoryById({ id: storyId }));
        const story = res.payload as StoriesApiModel;
        resolve(story);
      }, delay);
    });
  };

  /**
   * @param story story to fetch
   * @param intervals time intervals between requests
   */
  const refetchStoryUntilModerated = async (story: StoriesApiModel, intervals: number[] = defaultRefetchIntervals) => {
    if (story.status !== StoryStatuses.PENDING_MODERATION || isRefetching) return;

    setIsRefetching(true);
    for (const interval of intervals) {
      const newStory = await fetchStoryWithDelay({ storyId: story.id, delay: interval });

      if (newStory.status !== StoryStatuses.PENDING_MODERATION) {
        break;
      }
    }

    setIsRefetching(false);
  };

  return {
    refetchStoryUntilModerated,
  };
};
