import styled from 'styled-components';

interface RectangleProps {
  transparent?: boolean;
}

export const Rectangle = styled.div<RectangleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  ${(props) => (props.transparent ? 'background-color: unset' : 'background-color: var(--darkGreyButtonColor)')};
  color: #c7c7d9;
  border-radius: var(--buttonBorderRadius);
`;
