import { FC } from "react";

export const TikTokIcon:FC = () => (
  <svg
    width={29}
    height={28}
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6663 12.3242V9.15234C22.6299 9.15234 22.6299 9.15234 22.6299 9.15234C21.7549 9.15234 20.9163 8.89714 20.2236 8.42318C19.2028 7.76693 18.4736 6.70964 18.2549 5.50651C18.182 5.2513 18.182 4.99609 18.182 4.74089C18.182 4.70443 18.182 4.70443 18.182 4.66797H14.9736V17.4284C14.9736 18.9232 13.734 20.1628 12.2393 20.1628C10.7445 20.1628 9.50488 18.9232 9.50488 17.4284C9.50488 15.9336 10.7445 14.694 12.2393 14.694C12.5309 14.694 12.7861 14.7305 13.0778 14.8398V11.5586C12.7861 11.5221 12.5309 11.4857 12.2393 11.4857C8.95801 11.4857 6.33301 14.1471 6.33301 17.4284C6.33301 20.7096 8.95801 23.3346 12.2393 23.3346C15.5205 23.3346 18.182 20.7096 18.182 17.4284V10.9023C19.4215 11.8138 20.9528 12.3242 22.6299 12.3242H22.6663Z"
      fill="currentColor"
    />
  </svg>
);

