import { FC } from 'react';
import { theme } from '../../theme';

interface MenuIconProps {
  color?: string;
}

export const MenuIcon: FC<MenuIconProps> = ({ color }) => {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 2C1 1.7232 1.22175 1.5 1.4934 1.5H17.5066C17.78 1.5 18 1.72089 18 2C18 2.2768 17.7782 2.5 17.5066 2.5H1.4934C1.22002 2.5 1 2.27911 1 2ZM1 7C1 6.7232 1.22175 6.5 1.4934 6.5H12.5066C12.78 6.5 13 6.72089 13 7C13 7.2768 12.7782 7.5 12.5066 7.5H1.4934C1.22002 7.5 1 7.27911 1 7ZM1 12C1 11.7232 1.22175 11.5 1.4934 11.5H8.5066C8.77998 11.5 9 11.7209 9 12C9 12.2768 8.77825 12.5 8.5066 12.5H1.4934C1.22002 12.5 1 12.2791 1 12Z"
        stroke={color || theme.palette.primary.main}
        stroke-width="2"
      />
    </svg>
  );
};
