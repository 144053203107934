import { FC } from 'react';

export const ReelsIcon: FC = () => (
  <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.25405 0H14.7459C16.3261 0 17.7638 0.644531 18.8069 1.67969C19.8516 2.71647 20.5 4.14714 20.5 5.7194V14.2822C20.5 15.8512 19.8516 17.2803 18.8101 18.3171L18.8069 18.3203C17.7622 19.3571 16.3245 20 14.7476 20H6.25405C4.67226 20 3.23293 19.3555 2.18986 18.3203L2.15056 18.2764C1.13206 17.2445 0.5 15.8317 0.5 14.2806V5.7194C0.5 4.14551 1.1468 2.71647 2.18986 1.67969C3.23293 0.642904 4.67062 0 6.25405 0ZM15.4844 5.04883L15.4959 5.06673H19.0328C18.8904 4.14225 18.4466 3.31217 17.808 2.67578C17.022 1.89453 15.938 1.40951 14.7459 1.40951H13.2935L15.4844 5.04883ZM13.8486 5.06673L11.6462 1.40951H6.8157L9.04429 5.06673H13.8486ZM7.39537 5.06673L5.24046 1.52832C4.45284 1.71712 3.74709 2.1224 3.19199 2.67578C2.55338 3.31055 2.11127 4.14225 1.96717 5.06673H7.39537ZM19.082 6.47624H1.91804V14.2822C1.91804 15.4492 2.39127 16.512 3.15597 17.29L3.19199 17.3242C3.97798 18.1055 5.06362 18.5921 6.25405 18.5921H14.7459C15.938 18.5921 17.022 18.1071 17.8064 17.3275L17.8096 17.3242C18.594 16.5446 19.082 15.4671 19.082 14.2822V6.47624ZM8.84289 8.94369L13.2362 11.7839C13.3066 11.8294 13.3705 11.888 13.4212 11.9596C13.621 12.2477 13.5473 12.6432 13.2575 12.8418L8.88218 15.4085C8.77247 15.498 8.63165 15.5518 8.47609 15.5518C8.1224 15.5518 7.83584 15.2669 7.83584 14.9154V9.46452H7.83912C7.83912 9.33919 7.87678 9.21224 7.95374 9.10156C8.15679 8.81348 8.55469 8.74349 8.84289 8.94369Z"
      fill="currentColor"
    />
  </svg>
);
