import { FC } from 'react';

interface ShareIconIosProps {
  color?: string;
}

export const ShareIconIos: FC<ShareIconIosProps> = ({ color = 'white' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="none" />
    <path d="M8 9H5V21H19V9H16" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.1316 4.88184V13.8436" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.10022 5.56874L12.1322 3L15.5221 5.56874"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
