import React, { FC } from 'react';

import { CloseIconWrapper, Content, ModalOverlay } from './Modal.style';
import { SmallSquareButton, SmallSquareButtonType } from '../SmallSquareButton/SmallSquareButton';

export interface ModalProps {
  onClose: () => void;
  isCloseButton?: boolean;
  semiTransparentBgr?: boolean;
  alignItems?: string;
}

export const Modal: FC<ModalProps> = ({
  onClose,
  children,
  isCloseButton = true,
  semiTransparentBgr = false,
  alignItems = 'center',
}) => {
  const onClickContentHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <ModalOverlay onClick={onClose} alignItems={alignItems} semiTransparentBgr={semiTransparentBgr}>
      <Content onClick={onClickContentHandler}>
        {isCloseButton && (
          <CloseIconWrapper>
            <SmallSquareButton buttonType={SmallSquareButtonType.Close} onButtonClick={onClose} />
          </CloseIconWrapper>
        )}

        {children}
      </Content>
    </ModalOverlay>
  );
};
